.offer-element {
    
    padding: standard-padding;
    cursor: pointer;
    
    &.-active {

        > .icon {
            &.-velo {
                background-image: url('/assets/pikto-velo-active.svg');
            }
            &.-events {
                // background-image: url('/assets/pikto-events-active.svg');
                .active-circle {
                    display: block;
                }
            }
            &.-poollift {
                background-image: url('/assets/pikto-poollift-active.svg');
            }
            &.-eisgleiter {
                background-image: url('/assets/pikto-eisgleiter-active.svg');
            }
            &.-kayak {
                background-image: url('/assets/pikto-kayak-active.svg');
            }
            &.-camping {
                background-image: url('/assets/pikto-camping-active.svg');
            }
            &.-dualski {
                background-image: url('/assets/pikto-dualski-active.svg');
            }
            &.-fahrzeugmieten {
                background-image: url('/assets/pikto-fahrzeugmieten-active.svg');
            }
            &.-wandern {
                background-image: url('/assets/pikto-wandern-active.svg');
            }
        }
    }

    > .icon {
        
        height: rem(98px);
        width: rem(98px);
        background-repeat: no-repeat;
        background-position: center;

        @media all and ( max-width: breakpoints.small ) {
            width: auto;
        }

        &.-velo {
            background-image: url('/assets/pikto-velo.svg');
        }
        &.-events {

            text-align: center;

            .active-circle {
                display: none;
            }

            @keyframes blinker {

                0%   {
                    opacity: 1.0;
                }
                50%  {
                    opacity: 0.0;
                }
                100% {
                    opacity: 1.0;
                }

            }
            @keyframes blinker-2 {

                0%   {
                    opacity: 1.0;
                }
                50%  {
                    opacity: 0.0;
                }
                100% {
                    opacity: 1.0;
                }

            }

            @keyframes blinker-3 {

                0%   {
                    opacity: 1.0;
                }
                50%  {
                    opacity: 0.0;
                }
                100% {
                    opacity: 1.0;
                }

            }
            
            .blink{
                -webkit-animation-name: blinker;
                -webkit-animation-duration: 1.2s;
                -webkit-animation-iteration-count:infinite;
                -webkit-animation-timing-function:ease-in-out;
                -webkit-animation-direction: alternate;
            }

            .blink-2{
                -webkit-animation-name: blinker-2;
                -webkit-animation-duration: 0.8s;
                -webkit-animation-iteration-count:infinite;
                -webkit-animation-timing-function:ease-in-out;
                -webkit-animation-direction: alternate;
            }

            .blink-3{
                -webkit-animation-name: blinker-3;
                -webkit-animation-duration: 0.6s;
                -webkit-animation-iteration-count:infinite;
                -webkit-animation-timing-function:ease-in-out;
                -webkit-animation-direction: alternate;
            }
        }
        &.-poollift {
            background-image: url('/assets/pikto-poollift.svg');
        }
        &.-eisgleiter {
            background-image: url('/assets/pikto-eisgleiter.svg');
        }
        &.-kayak {
            background-image: url('/assets/pikto-kayak.svg');
        }
        &.-camping {
            background-image: url('/assets/pikto-camping.svg');
        }
        &.-dualski {
            background-image: url('/assets/pikto-dualski.svg');
        }
        &.-fahrzeugmieten {
            background-image: url('/assets/pikto-fahrzeugmieten.svg');
        }
        &.-wandern {
            background-image: url('/assets/pikto-wandern.svg');
        }
    }

    > .title {
        
        width: rem(98px);
        padding-top: rem(10px);
        text-align: center;

        @media all and ( max-width: breakpoints.small ) {
            width: auto;
        }

    }


    @media all and ( max-width: breakpoints.x-small ) { 
        padding: rem( 5px );
    }

}