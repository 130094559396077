@css {
  @font-face{
      font-family:"OpenSans-Bold";
      src:  url('fonts/OpenSans-Bold.woff') format('woff'),
            url("fonts/OpenSans-Bold.ttf") format("truetype");
  }
  @font-face{
      font-family:"OpenSans-Semibold";
      src:  url("fonts/OpenSans-Semibold.woff") format("woff"),
            url("fonts/OpenSans-Semibold.ttf") format("truetype");
  }
  @font-face{
      font-family:"OpenSans";
      src:  url("fonts/OpenSans.woff") format("woff"),
            url("fonts/OpenSans.ttf") format("truetype");
  }
}


body, p {
  font-family: 'OpenSans', Arial, sans-serif;
  color: text-color;
  text-rendering: optimizeLegibility,
}

h1, h2 {
  font-family: 'OpenSans-Bold';
}

h1 {
  font-size: 26px;
  letter-spacing: 0.4px;
  line-height: 36px;
}

h2 {
  font-size: 17px;
  letter-spacing: 0.5px;
  line-height: 27px;
}

a {
  color: black;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}