.info-container {
    
    > h2, .website {
        padding-top: rem( 20px );
    }

    > .subtitle {
        padding-bottom: rem(6px);
    }

}