.footer-button {
    
    padding: standard-padding;
    border-top: 1px solid white;
    cursor: pointer;
    text-align: center;

    &:hover {
        background-color: action-color;
    }

    &.-go {
        border-left: 1px solid white;
    }

    .arrow {
        font-size: rem(22px);
        padding-left: rem(5px);
        margin-top: rem(2px);
        position: relative; 
        top: rem(2px);
    }

}