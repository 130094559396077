.places-search {
    
    position: absolute;
    top: "calc( %s + %s )" % (filter-header-height standard-padding);
    left: standard-padding;
    z-index: 10;
    border: 2px solid brand-primary;
    border-radius: rem(2px);
    font-size: 16px; 
    background-image: url('/assets/icon-search.svg');
    background-repeat: no-repeat;
    background-position-x: 1px;
    background-position-y: 3px;
    font-family: 'OpenSans-Semibold';

    &.-active {
        padding-left: rem(40px);
        width: rem(220px);
        transition: width 0.5s ease-out, padding-left 0.5s ease;
    }

    &.-passive {
        padding-left: rem(25px);
        width: rem(30px);
        transition: width 0.5s ease-out, padding-left 0.5s ease;
    }

}