.offers-container {
    
    max-width: filter-menu-width-desktop;
    width: filter-menu-width-desktop;
    max-height: "calc( 100% - %s )" % filter-header-height;
    // position: absolute;
    background-color: brand-primary;
    color: white;
    cursor: pointer;
    transform: translateY(-100%);
    z-index: 50;
    position: fixed;
    overflow: auto;

    @media all and ( max-width: breakpoints.small ) {
        
        width: 100vw;
        max-width: none;

    }

    &.-show {
        transform: translateY(0);
        transition: transform 0.5s ease, -webkit-transform 0.5s ease;
    }

    &.-hide {
        transform: translateY(-110%);
        transition: transform 0.5s ease, -webkit-transform 0.5s ease;
    }

}