.control-ui {

    border: 2px solid brand-primary;
    border-radius: 50%;
    box-shadow: 0 2px 6px rgba(0,0,0,.3);
    background-color: white;
    background-repeat: no-repeat;
    cursor: pointer;
    
    &.-satellite {
        text-align: center;
        border-radius: 0;
        height: rem(80px);
        width: rem(80px);
        background-image: url('/assets/icon-maptype-satellite.png');
        background-color: transparent;
        border: none;
        background-size: cover;

        &.-active {
            background-image: url('/assets/icon-maptype-roadmap.png');
        }

        @media all and ( max-width: breakpoints.small ) {
            position: absolute;
            top: 55px;
        }
    }

    &.-in, &.-out {

        height: rem(34px);
        width: rem(34px);

        @media all and ( max-width: breakpoints.small ) {
            display: none;
        }
        
    }

    &.-in{
        background-image: url('/assets/icon-plus.svg');
        background-position: 9px 9px;
        &:hover {
            background-color: brand-primary;
            background-image: url('/assets/icon-plus-active.svg');
        }
    }
    &.-out{
        background-image: url('/assets/icon-minus.svg');
        background-position: 9px 13px;
        &:hover {
            background-color: brand-primary;
            background-image: url('/assets/icon-minus-active.svg');
        }
    }


}