.map-control {
    
    border-radius: 50%;
    height: rem(44px);
    width: rem(44px)

    &.-satellite, &.-roadmap {
        margin-top: standard-padding;
        margin-left: 10px;
        margin-right: standard-padding;
    }

    &.-roadmap {
        right: 45px !important;

        &.-active {
            > .control-ui {
                background-image: url('/assets/icon-maptype-roadmap.png');
            }
        }
    }

    &.-zoomin, &.-zoomout {
        margin-right: 10px;
    }
    
    &.-zoomin {
        bottom: 118px !important;
        right: 10px !important;
    }
    
    &.-zoomout {
        bottom: 69px !important;
        right: 10px !important;
    }

    &.-moveright {
        transform: translateX(10);
        transition: transform 0.5s ease, -webkit-transform 0.5s ease;
    }

    &.-moveleft {
        transform: translateX(-350px);
        transition: transform 0.5s ease, -webkit-transform 0.5s ease;
    }



}