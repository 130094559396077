.offer-detail {
    
    background-color: white;
    color: text-color;
    padding: 0;
    position: absolute; 
    height: 100vh;
    width: rem(360px);
    top: 0;
    right: 0;
    box-shadow: 0 2px 4px 0 shadow;
    transform: translateX(378px);
    z-index: 20;

    > .content {
        max-height: 100%;
        overflow: auto;
    }

    @media all and ( max-width: breakpoints.small ) {
        top: rem( filter-header-height );
        > .content {
            max-height: "calc( 100% - %s )" % filter-header-height;
            overflow: auto;
        }
    }

    &.-show {
        transform: translateX(0);
        transition: transform 0.5s ease, -webkit-transform 0.5s ease;
    }

    &.-hide {
        transform: translateX(378px);
        transition: transform 0.5s ease, -webkit-transform 0.5s ease;
    }

}