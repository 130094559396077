.close-button {
    
    position: absolute;
    top: rem(224px);
    right: rem(340px);
    background-image: url('/assets/icon-close.svg');
    background-repeat no-repeat;
    width: rem(36px);
    height: rem(36px);
    cursor: pointer;

    @media all and ( max-width: breakpoints.xx-small ) {
        width: rem(32px);
        height: rem(32px);
        top: rem(6px);
        right: rem(5px);
    }

    @media all and ( max-height: 315px ) {
        width: rem(32px);
        height: rem(32px);
        top: rem(6px);
        right: rem(5px);
    }

    &.-active {
        background-image: url('/assets/icon-close-active.svg');
    }

}