@import "components/*.styl"
@import "helpers/*.styl"
@import "text-styling.styl"

/* Always set the map height explicitly to define the size of the div
* element that contains the map. */
#map {
    height: 100%;
}
/* Optional: Makes the sample page fill the window. */
html, body {
    height: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;
}

.main-container, .google-map {
    height: 100vh;
    width: 100vw;
}