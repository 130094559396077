.filter-menu {
    
    background-color: brand-primary;
    color: white;
    padding: standard-padding;
    padding-right: rem(50px);
    cursor: pointer;
    position: relative;
    z-index: 100;
    width: filter-menu-width-desktop;

    @media all and ( max-width: breakpoints.small ) {
        width: 100vw;
    }
    
    > .arrow {
        position: absolute;
        top: 24px;
        right: 0;
        width: rem(12px);
        height: rem(8px);
        margin-right: standard-padding;
        background-image: url('/assets/icon-arrow-small.svg');
        background-repeat: no-repeat;
    }

    &.-open {
        > .arrow {
            transform: scaleY(-1); 
        }
    }

}